@use '@angular/material' as mat;
@use '../../soletanche-theme' as zl;

.mat-accent .mdc-list-item__start,
.mat-accent .mdc-list-item__end {
  --mdc-radio-selected-icon-color: #{mat.get-color-from-palette(
      zl.$primary,
      default
    )};
}
