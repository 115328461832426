@use '@angular/material' as mat;

:root {
  .mdc-text-field--filled,
  .mat-mdc-form-field-focus-overlay {
    background-color: transparent;
  }

  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: transparent;
  }
}
