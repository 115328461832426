// fxLayout="column" & fxLayout="row"
@mixin layout {
  @each $value in (column, row) {
    .flex-layout-#{$value} {
      display: flex;
      flex-direction: $value;
      box-sizing: border-box;
    }
  }
}
@include layout;

// flex wrap
@mixin wrap {
  @each $value in (wrap, nowrap) {
    .flex-#{$value} {
      flex-wrap: $value;
    }
  }
}
@include wrap;

/**********************************************************************************************************************/
// fxFlex
/**********************************************************************************************************************/
.flex {
  display: flex;
}

@mixin flex-styles($value) {
  box-sizing: border-box;
  @if $value == grow {
    flex: 1 1 100%;
  } @else if $value == nogrow {
    flex: 0 1 auto;
  } @else if $value == auto {
    flex: 1 1 auto;
  } @else if $value == none {
    flex: 0 0 auto;
  } @else if $value == 1 {
    flex: 1 1 0;
  }
}

// fxFlex="50"
@mixin flex {
  $flex-values: 10, 15, 20, 25, 30, 33, 35, 38, 40, 45, 50, 55, 60, 65, 70, 75,
    80, 85, 90, 95, 100, 20px, 24px, 30px, 40px, 50px, 56px, 60px, 75px, 100px,
    110px, 130px, 200px, 250px, 260px, 300px, 310px, 400px, 600px;
  $size-values: grow, nogrow, auto, none, 1;

  @each $value in $size-values {
    .flex-#{$value} {
      @include flex-styles($value);
    }
  }

  @each $value in $flex-values {
    .flex-#{$value} {
      flex: 1 1 100%;
      box-sizing: border-box;
    }

    .flex-layout-column > .flex-#{$value} {
      @if unit($value) == 'px' {
        max-height: $value;
      } @else {
        max-height: $value * 1% !important;
      }
    }

    .flex > .flex-#{$value},
    .flex-layout-row > .flex-#{$value} {
      @if unit($value) == 'px' {
        max-width: $value;
      } @else {
        max-width: $value * 1% !important;
      }
    }
  }
}
@include flex;

// fxFill fxFlexFill
.flex-fill {
  height: 100%;
  min-height: 100%;
  min-width: 100%;
  width: 100%;
}

.flex-no-shrink {
  flex-shrink: 0;
  white-space: nowrap;
}

.flex-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

/**********************************************************************************************************************/
// fxLayoutGap
/**********************************************************************************************************************/

// eg: fxLayoutGap="10px"
@mixin layout-gap {
  $gap-values: 2, 5, 10, 2px, 5px, 10px, 15px, 20px, 25px, 30px, 40px, 50px,
    75px;

  @each $value in $gap-values {
    .flex-gap-#{$value} {
      @if unit($value) == 'px' {
        gap: $value;
      } @else {
        gap: percentage(calc($value / 100));
      }
    }
  }
}
@include layout-gap;

/**********************************************************************************************************************/
// fxLayoutAlign
/**********************************************************************************************************************/

// eg: fxLayoutAlign="start center"
@mixin layout-align {
  $vertical-props: (
    'none': flex-start,
    'start': flex-start,
    'center': center,
    'end': flex-end,
    'baseline': baseline,
    'space-around': space-around,
    'space-between': space-between,
    'space-evenly': space-evenly,
  );
  $horizontal-props: (
    'none': stretch,
    'start': flex-start,
    'center': center,
    'end': flex-end,
    'baseline': baseline,
    'stretch': stretch,
  );

  @each $vertical-name, $vertical-value in $vertical-props {
    $class-name: flex-align-#{$vertical-name};

    @if $vertical-name == none {
      $class-name: flex-align;
    }

    .#{$class-name} {
      @each $horizontal-name, $horizontal-value in $horizontal-props {
        @if $horizontal-name == none {
          place-content: $horizontal-value $vertical-value;
          align-items: $horizontal-value;
        } @else {
          &--#{$horizontal-name} {
            place-content: $horizontal-value $vertical-value;
            align-items: $horizontal-value;
          }
        }
      }
    }
  }
}
@include layout-align;
