.app-title-container {
  background: #3498db;
  color: white;
  height: 48px;
  line-height: 48px;
  padding-left: 20px;
  vertical-align: middle;
  font-weight: 300;
  font-size: 18px;
  padding-right: 15px;

  .app-title {
    display: inline-block;
  }

  .btn {
    background: #2980b9;
    border-radius: 3px;
    height: 30px;
    line-height: 30px;
    padding-top: 0px;
    padding-bottom: 0px;
    vertical-align: middle;
    margin-top: 10px;
    color: white;
    font-weight: bold;
  }
}

.nav-app {
  background-color: white;

  &.nav-pills > li > a {
    padding-top: 0px;
    padding-bottom: 0px;
    height: 50px;
    line-height: 50px;
    vertical-align: middle;
    border-radius: 0px !important;
  }

  &.nav-pills > li.active > a,
  .nav-pills > li.active > a:hover,
  .nav-pills > li.active > a:focus {
    background: #2980b9;
  }
}

.dashboard-settings-container {
  border-radius: 0px;
  border-bottom: 1px solid #ddd;
  border-left: 1px solid #ddd;
  border-top: 1px solid #ddd;
  padding: 10px;
  overflow: hidden;
  // height:200px;
  padding-bottom: 20px;
  background: white;
}

// used in compund param form autocomplete options
// to overwrite the color of the bootstrap class '.badge'
.badge-green {
  background-color: #0a0 !important;
  color: #fff !important;
  margin-right: 1em;
}
.badge-blue {
  background-color: #00a !important;
  color: #fff !important;
  margin-right: 1em;
}

$navbar-toggle-button-width: 64px;
