.settings-overview-group {
  vertical-align: top;
  border-right: 1px solid #eee;

  h4 {
    margin-top: 10px;
    padding-bottom: 5px;
    font-size: 16px;
    margin-bottom: 5px;
  }

  .list-group-item {
    text-align: left;
    padding-top: 3px;
    padding-bottom: 3px;
    border-radius: 0px;
    border: 0px;
  }
}
