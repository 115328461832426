@use '@angular/material' as mat;
@use '../../soletanche-theme' as zl;

:root {
  --mat-standard-button-toggle-selected-state-background-color: #{mat.get-color-from-palette(
      zl.$primary,
      default
    )};
  --mat-standard-button-toggle-background-color: #c3c3c3;
}
