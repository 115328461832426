/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
@use 'soletanche-theme' as zl;
@use '@mat-datetimepicker/core/datetimepicker/datetimepicker-theme' as dtp;

@import 'app/style/include-bootstrap';
@import 'app/style/forms';
@import 'app/style/panels';
@import 'app/style/app';
@import 'app/style/dashboard-settings';
@import 'app/style/flex-layout';
@import 'app/style/mdc-dialog';
@import 'app/style/card';
@import 'app/style/form-fields';
@import 'app/style/table';
@import 'app/style/tabs';
@import 'app/style/buttons';
@import 'app/style/radio';
@import 'app/style/highcharts';

// Using the $theme variable from the pre-built theme you can call the theming function
@include dtp.mat-datetimepicker-theme(zl.$zl-theme);

html,
body {
  width: 100%;
  height: 100% !important; // need the !important, otherwise conflict with sweetalert2
  font-family: 'Open Sans', sans-serif;
}

app-root {
  display: block;
  height: 100%;
  width: 100%;
}

.no-padding {
  padding: 0 !important;
}

.top-buffer {
  margin-top: 20px;
}

.container-main {
  display: inline-block;
  width: calc(100% - 30px);
  margin: 15px;
  border-radius: 2px;
  padding: 20px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 5px 0px;
}

.container-main-full {
  display: inline-block;
  width: 100%;
  border-radius: 0px;
  padding: 0px;
  background-color: white;
  border-left: 1px solid #ddd;
  min-height: calc(100vh - 102px);
}

.block {
  display: inline-block;
  width: calc(100% - 30px);
  margin: 15px;
  border-radius: 2px;
  padding: 20px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 5px 0px;
}

.block-4 {
  display: inline-block;
  width: calc(33% - 10px);
  margin: 5px;
  margin-bottom: 10px;
  border-radius: 2px;
  padding: 5px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 5px 0px;
}

.block-2 {
  display: inline-block;
  width: calc(48% - 10px);
  margin: 10px;
  border-radius: 2px;
  padding: 5px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 5px 0px;
}

.directionIcon {
  font-size: large;
}

//*********************** V2 **********************

$sb-title-wrapper-padding: 40px;

.sb-title-wrapper {
  padding-top: $sb-title-wrapper-padding;
  padding-bottom: $sb-title-wrapper-padding;
}

.sb-title,
.sb-subtitle {
  color: mat.get-color-from-palette(zl.$primary, default);
  padding: 0;
  margin: 0;
}

.sb-title {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.5em;
}

.sb-subtitle {
  font-size: 1.17em;
}

.sb-btn-fab {
  position: sticky !important;
  right: 40px;
  bottom: 40px;
}

.sb-list-group {
  width: 80%;
}

.sb-list-headers {
  padding: 0 10px;
  color: mat.get-color-from-palette(zl.$accent, darker);
  font-weight: bold;
  font-size: larger;
}

.sb-list-line {
  border: mat.get-color-from-palette(zl.$accent, default) 1px solid;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 20px 10px;
  color: mat.get-color-from-palette(zl.$accent, default);
  cursor: pointer;
  font-size: larger;
  background-color: white;
}

.sb-list-first-item {
  color: mat.get-color-from-palette(zl.$primary, default);
  font-weight: bold;
}

.map-tooltip {
  position: relative;
  padding: 5px;
  background: mat.get-color-from-palette(zl.$primary, default);
  color: white;
  opacity: 1;
  white-space: nowrap;
  font-size: medium;
}

.fab-buttons {
  position: absolute !important;
  bottom: 20px;
  right: 30px;

  button {
    margin-top: 8px;
  }
}

$button-corner-radius: 8px;

.right-toolbar {
  position: absolute;
  right: 30px;
  height: 100%;
  top: 30px;
}

.top-toolbar {
  position: absolute;
  top: 15px;
  width: 100%;
  right: (30px * 2 + 45px);
}

.button-bar {
  .button-el {
    width: 45px;
    height: 45px;
    background-color: white;
  }

  .alone-button {
    border-radius: $button-corner-radius;
  }

  .clicked {
    color: mat.get-color-from-palette(zl.$primary, default);
    border-style: inset;
  }
}

.vertical-bar {
  .button-el:first-child {
    border-top-left-radius: $button-corner-radius;
    border-top-right-radius: $button-corner-radius;
  }

  .button-el:last-child {
    border-bottom-left-radius: $button-corner-radius;
    border-bottom-right-radius: $button-corner-radius;
  }
}

.horizontal-bar {
  .button-el:first-child {
    border-top-left-radius: $button-corner-radius;
    border-bottom-left-radius: $button-corner-radius;
  }

  .button-el:last-child {
    border-top-right-radius: $button-corner-radius;
    border-bottom-right-radius: $button-corner-radius;
  }
}

.horizontal-bar-position {
  position: absolute;
  top: 5px;
  right: 80px;
}

.export-buttons-position {
  margin-top: 40px;
  margin-right: 6px;
}

.swal-wide-sb {
  width: 850px !important;
}

.swal2-shown {
  display: flex;
}

mat-button-toggle {
  text-align: center;
}

.mat-button-toggle {
  background-color: mat.get-color-from-palette(zl.$accent);
  color: mat.get-color-from-palette(zl.$accent, default-contrast);
}

.mat-button-toggle-checked {
  background-color: mat.get-color-from-palette(zl.$primary);
  color: mat.get-color-from-palette(zl.$primary, default-contrast);
}

.progressbar {
  border-radius: 20px;
  border: mat.get-color-from-palette(zl.$accent, default) 1px solid;
  height: 10px;
}

.progressbar,
.inner-progressbar {
  box-sizing: unset !important;
}

.progressbar > div {
  background-color: mat.get-color-from-palette(zl.$primary, default);
  height: 10px;
  border-radius: 10px;
}

.boxShadow {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

//for scene 3D three.js
#gui {
  margin-top: 50px;
  margin-right: 2px;
}

.selectBox {
  border: 1px solid mat.get-color-from-palette(zl.$primary, default);
  background-color: rgba(255, 195, 197, 0.3);
  position: fixed;
}

//For color picker
mcc-color-picker .transparent {
  position: initial !important;
}

.grayColor {
  color: mat.get-color-from-palette(zl.$accent, default) !important;
}

.cdk-overlay-container .mat-menu-panel {
  max-width: none;
}

.layer-selector {
  position: absolute;
  height: auto;
  overflow-x: auto;
  overflow-y: auto;
  top: 50%;
  transform: translate(0, -50%);
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 100;
  border-radius: 0 10px 10px 0;
}

$layer-selector-height: 36px;
.layer-selector-button {
  height: $layer-selector-height;
  position: absolute;
  top: calc(50% - #{$layer-selector-height}/ 2);
  padding: 7px;
  background-color: rgba(255, 255, 255, 0.8);
  cursor: pointer;
  sup {
    color: red;
    font-weight: bold;
  }
}

//tool tip in hydrofraise map
.tooltip {
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  color: white;
  padding: 4px 8px;
  opacity: 0.7;
  white-space: nowrap;
}

.tooltip-measure {
  opacity: 1;
  font-weight: bold;
}

.tooltip-measure:before {
  border-top: 6px solid rgba(0, 0, 0, 0.5);
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  content: '';
  position: absolute;
  bottom: -6px;
  margin-left: -7px;
  left: 50%;
}

.hidden {
  visibility: hidden;
}

.warn-message {
  position: fixed;
  left: 40vw;
  top: 100px;
  width: 400px;
}

.cdk-overlay-pane {
  max-width: 100vw !important;
}

mat-icon.fa-2x {
  width: auto;
  height: auto;
}

.checkbox-container {
  align-items: baseline;
}
